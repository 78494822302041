import React from "react"
import PropTypes from "prop-types"

import "./button.scss"

class Button extends React.Component {

    render(){

        const { label, type, theme, display } = this.props

        switch(type){

            case 'underline':
                return (
                    <div className={`button-underline ${theme === 'light'? 'button-underline-light':''} font-agrandir py-3 tracking-widest uppercase text-sm ${display}`} onClick={this.props.onClick}>
                        {label}
                    </div>
            )
            
            case 'solid':
                return (
                    <div className={`button-solid font-agrandir py-4 px-8 uppercase`} onClick={this.props.onClick}>
                        <span>{label}</span>
                    </div>
            )
            
            case 'outline':
                return (
                    <div className={`button-outline font-agrandir py-3 px-6 uppercase text-sm`} onClick={this.props.onClick}>
                        {label}
                    </div>
            )

            default: 
                return(
                    <div className={`button-underline font-agrandir py-2 tracking-widest`} onClick={this.props.onClick}>
                        {label}
                    </div>
                )
        }

    }

}

Button.defaultProps = {
    type:'underline',
    display:'inline',
}

Button.propTypes = {
    type: PropTypes.string,
    display: PropTypes.string,
    label:PropTypes.string.isRequired
}

export default Button