import React from "react"
import { Sine, gsap, CSSPlugin } from "gsap"
import Cookie from "universal-cookie"

import PageTransitionLink from "../../components/transition/link"
import LogoLightLG from "../../images/logo-light.svg"
import LogoDarkLG from "../../images/logo-dark.svg"
import HamburgerDark from "../../images/hamburger.svg"
import HamburgerLight from "../../images/hamburger-light.svg"
import HamburgerClose from "../../images/hamburger-close.svg"
import FacebookIcon from "../../images/facebook-outline-icon.svg"
import InstagramIcon from "../../images/instagram-outline-icon.svg"
import YoutubeIcon from "../../images/youtube-outline-icon.svg"
import SpotifyIcon from "../../images/spotify-outline-icon.svg"
import Languages from "../../lib/languages"

gsap.registerPlugin(CSSPlugin)

class Hamburger extends React.Component {

    constructor(props) {
        super(props)

        const cookie = new Cookie()

        this.state = {
            language: cookie.get('drop_lang') || Languages.defaultLangKey,
            languageOpen: false
        }

        this.overlay = React.createRef()
    }

    componentWillMount() {
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
            document.querySelector('body').classList.remove('fixed')
        }
    }

    open = () => {

        gsap.to(this.overlay, .2, { css:{ height:'100vh' }, ease: Sine.easeOut })
        // gsap.to(this.overlay, .2, { height: `${window.innerHeight}px`, ease: Sine.easeOut })
        document.querySelector('body').classList.add('fixed')
    }

    close = () => {
        this.toggleLanguage()
        gsap.to(this.overlay, .2, { css:{ height:'0px' }, ease: Sine.easeOut })
        document.querySelector('body').classList.remove('fixed')
    }

    toggleLanguage = () => {

        if (this.state.languageOpen) {
            this.setState({ languageOpen: false })
            gsap.to(this.languageDraw, 0.1, { height: 0, ease: Sine.easeOut })

        } else {
            this.setState({ languageOpen: true })
            gsap.to(this.languageDraw, 0.1, {
                height: `128px`,
                ease: Sine.easeOut,
            })
        }
    }

    render() {

        // const hidden = this.state.languageOpen ? "visible" : "hidden"

        return (
            <section style={{zIndex:9999}}>
                <div className={"w-screen h-0 coral-white-gradient absolute flex flex-col"} style={{ top: "-64px", zIndex: 200, overflow: "hidden" }} ref={div => this.overlay = div}>
                    <div className={"py-4 px-6 clearfix"}>
                        <img src={HamburgerClose} className={"float-left"} alt="close" onClick={this.close} />
                        {/* <span className={"text-white text-sm font-montserrat-regular float-right"} onClick={this.toggleLanguage}>select language</span> */}
                    </div>
                    {/* <nav className={"list-none w-full gravel-pattern text-center h-0"} style={{ overflow: hidden }} ref={div => (this.languageDraw = div)}>
                        {
                            Languages.messages.map((item, index) => {
                                return (
                                    <li className={"block cursor-pointer py-1"} onClick={() => this.setLanguage(item)} key={`lang-${index}`}>
                                        <span className={"text-white pr-6"}><strong className={"text-white"}>{item.code}</strong> {item.label}</span>
                                    </li>
                                )
                            })
                        }
                    </nav> */}
                    <nav className={"list-none py-6 px-6"}>
                        <li className={"font-montserrat-extra-bold text-2xl text-white uppercase pb-3"}>
                            <PageTransitionLink href={`/${this.state.language}/festivals`} exit={this.props.exit} entry={this.props.entry}>
                                festivals
                            </PageTransitionLink>
                        </li>
                        <li className={"font-montserrat-extra-bold text-2xl text-white uppercase pb-3"}>
                            <PageTransitionLink href={`/${this.state.language}/music`} exit={this.props.exit} entry={this.props.entry}>
                                music
                            </PageTransitionLink>
                        </li>
                        {/* <li className={"font-montserrat-extra-bold text-2xl text-white uppercase pb-3"}>
                            <PageTransitionLink href={`/${this.state.language}/tv`} exit={this.props.exit} entry={this.props.entry}>
                                drop tv
                            </PageTransitionLink>
                        </li> */}
                        <li className={"font-montserrat-extra-bold text-2xl text-white uppercase pb-3"}>
                            <PageTransitionLink href={`/${this.state.language}/surfing`} exit={this.props.exit} entry={this.props.entry}>
                                surfing
                            </PageTransitionLink>
                        </li>
                        {/* <li className={"font-montserrat-extra-bold text-2xl text-white uppercase pb-3"}>
                            <PageTransitionLink href={`/${this.state.language}/lifestyle`} exit={this.props.exit} entry={this.props.entry}>
                                lifestyle
                            </PageTransitionLink>
                        </li> */}
                        <li className={"py-6"}>
                            <span className={"text-white text-sm font-montserrat-regular"}>Stay connected</span>
                            <nav className={"list-none flex flex-row pt-2"}>
                                <li className={"mr-2"}>
                                    <a href="https://www.facebook.com/thedropfestival/" target="_blank" rel="noopener noreferrer">
                                        <img src={FacebookIcon} alt="" />
                                    </a>
                                </li>
                                <li className={"mr-2"}>
                                    <a href="https://www.instagram.com/thedropfestival" target="_blank" rel="noopener noreferrer">
                                        <img src={InstagramIcon} alt="" />
                                    </a>
                                </li>
                                <li className={"mr-2"}>
                                    <a href="https://www.youtube.com/channel/UC2R6luglFhbFyPDVjr2UNhA" target="_blank" rel="noopener noreferrer">
                                        <img src={YoutubeIcon} alt="" />
                                    </a>
                                </li>
                                <li className={"mr-2"}>
                                    <a href="https://open.spotify.com/user/nmv5ubpzdxs8si8w1nxnjtcm5" target="_blank" rel="noopener noreferrer">
                                        <img src={SpotifyIcon} alt="" />
                                    </a>
                                </li>
                            </nav>
                        </li>
                    </nav>
                </div>
                <div className={`${(this.props.theme === 'dark' ? 'bg-black border-gray-900' : 'bg-white border-gray-300')} flex fixed w-full px-4 py-5 border border-solid border-gray-300`} style={{ top: 0, zIndex: 100 }}>
                    <div className={"flex-none text-center"} onClick={this.open}>
                        <img className={"w-8 mx-auto"} src={(this.props.theme === 'dark' ? HamburgerLight : HamburgerDark)} alt=""/>
                    </div>
                    <div className={"flex-grow "}>
                        {/* <PageTransitionLink href={'/'} exit={this.props.exit} entry={this.props.entry}> */}
                        <PageTransitionLink href={`/${this.state.language}`} exit={this.props.exit} entry={this.props.entry}>
                            <img width="118px" className={"ml-4"} src={(this.props.theme === 'dark' ? LogoLightLG : LogoDarkLG)} alt="" style={{maxWidth:'200px'}} />
                        </PageTransitionLink>
                        {/* </PageTransitionLink> */}
                    </div>
                    {/* <div className={"flex-grow text-center"}>
                        <img width="18px" className={"mx-auto mt-1"} src={(this.props.theme === 'dark' ? SearchIconLight : SearchIconDark)} alt=""/>
                    </div> */}
                    <div className={"flex-none text-right"}>
                        <a href="https://www.eventbrite.com.au/o/the-drop-festival-18287173371" target="_blank" rel="noopener noreferrer" className={"font-agrandir text-coral text-sm"}>BUY TICKETS</a>
                    </div>
                </div>
            </section>
        )
    }

}

export default Hamburger
