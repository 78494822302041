import React from "react"
import lottie from 'lottie-web'
import { isMobile, isTablet } from "react-device-detect"
import widescreenAnimationData from "./widescreen.json"
import portraitAnimationData from "./portrait.json"

class PageTransition extends React.Component {
  constructor(props) {
    super(props)
    this.el = React.createRef()
  }
  componentDidMount() {
    this.lottie = lottie.loadAnimation({
      container: this.el.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: isMobile || isTablet ? portraitAnimationData : widescreenAnimationData,
      rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
    })
  }
  play() {
    this.lottie.play()
  }
  render() {
    return (
      <div
        ref={this.el}
        style={{
          width: '100vw',
          height: '100vh'
        }}
      />
    )
  }
}

export default PageTransition
