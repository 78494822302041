import React from "react"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import PropTypes from "prop-types"

import Button from "../button/button"

import "./signup.scss"

class Signup extends React.Component {

    render(){
        
        return(
            <section className={`w-full signup-background-${this.props.theme}`}>
                <div className={"container mx-auto"}>
                    <div className={"mx-auto w-full lg:w-2/3"}>
                    <MailchimpSubscribe
                        url={"https://thedropfestival.us17.list-manage.com/subscribe/post?u=5535885f8876e31ead74dbb0d&amp;id=a0b50d6c4f"}
                        // url={"https://slik.us4.list-manage.com/subscribe/post?u=8d28b952c75edf338c71e48a4&amp;id=84d991a3b2"}
                        render={({ subscribe, status, message }) => (
                            <EmailForm
                              status={status}
                              message={message}
                              onValidated={formData => subscribe(formData)}
                            />
                        )}
                    />
                    </div>
                </div>
            </section>
        )
    }

}

const EmailForm = ({ status, message, onValidated }) => {

    let email, name

    const submit = () =>
      email &&
      name &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
        NAME: name.value
      })

    return (
        <div className={"text-center py-20"}>
            <h1 className={"font-agrandir text-xl md:text-3xl"}>The Drop in your inbox</h1>
            <p className={"font-montserrat-regular"}>Stay up to date with all the latest news, content and festivals from The Drop</p>
            <div>
                <div>
                {status === "sending" && <div>sending...</div>}
                {status === "error" && (
                <div
                    style={{ color: "red" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
                )}
                {status === "success" && (
                <div
                    style={{ color: "blue" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
                )}
                </div>
                <input
                    className={"input border-coral py-4 px-4 my-2 font-montserrat-regular w-10/12 md:w-1/2"}
                    ref={node => (name = node)}
                    type="text"
                    placeholder="Your name"
                />
                <br />
                <input
                    className={"input border-coral py-4 px-4 my-2 font-montserrat-regular w-10/12 md:w-1/2"}
                    ref={node => (email = node)}
                    type="email"
                    placeholder="Your email"
                />
                <br />
                <div className={"my-8"}>
                  <Button type={"solid"} label={"subscribe"} onClick={submit} />
                </div>
            </div>
        </div>
    )
}


Signup.defaultProps = {
    theme:'light'
}

Signup.propTypes = {
    theme:PropTypes.string
}

export default Signup