import React from "react"
import PropTypes from "prop-types"

import Navigation from "./navigation"
import Hamburger from "./hamburger"
import LanguageSelector from "./language"

const Header = ({theme, path, exit, entry}) => (
  <header className={"mb-16 md:mb-0 relative"} style={{zIndex:9000}}>
    <section className={"hidden sm:hidden md:block"}>
      <LanguageSelector />
      <Navigation theme={theme} path={path} exit={exit} entry={entry} />
      {/* <Navigation theme={theme} path={path} /> */}
    </section>  
    <section className={"block sm:block md:hidden"}>
      <Hamburger theme={theme} path={path} exit={exit} entry={entry} className={"mb-6"}/>
      {/* <Hamburger theme={theme} path={path} className={"mb-6"}/> */}
    </section>
  </header>
)

Header.defaultProps = {
  theme:'light'
}

Header.propTypes = {
  theme: PropTypes.string,
  path:PropTypes.string.isRequired
}

export default Header
