import React from "react"
import { Link } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"

const ConditionalWrapper = ({ condition, trueWrapper, falseWrapper, children }) => condition ? trueWrapper(children) : falseWrapper(children)

const PageTransitionLink = ({href, exit, entry, children }) => (

    <ConditionalWrapper
        condition={exit}
        trueWrapper={children => <TransitionLink to={`${href}/`} exit={exit} entry={entry}>{children}</TransitionLink>}
        falseWrapper={children => <Link to={`${href}/`}>{children}</Link>}
    >{children}</ConditionalWrapper>

)

export default PageTransitionLink