import React from "react"
import PropTypes from "prop-types"

import Header from "../header/header"
import Footer from "../footer/footer"

const Layout = ({ children, path, locale, theme, exit, entry }) => {
  return (
    <section>
      <div className={`w-full ${theme === 'light' ? 'bg-white' : 'bg-gray-900'}`}>
        <Header theme={theme} path={path} exit={exit} entry={entry} />
        {children}
        <Footer path={path} locale={locale} theme={theme} />
      </div>
    </section>
  )
}

Layout.defaultProps = {
  theme:'light'
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  theme:PropTypes.string
}

export default Layout
