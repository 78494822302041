import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import "./footer.scss"
import Signup from "../signup/signup"
import Logo from "../../images/logo-light-full.svg"
import LogoDarkLG from "../../images/logo-light.svg"
import FacebookIcon from "../../images/facebook-icon.svg"
import InstagramIcon from "../../images/instagram-icon.svg"
import YoutubeIcon from "../../images/youtube-icon.svg"
import SpotifyIcon from "../../images/spotify-icon.svg"

class Footer extends React.Component {

    render(){
        
        const {locale, theme} = this.props

        return(
            <footer>
                <Signup theme={theme} />
                <section className={"footer-pattern py-6"}>
                    <div className={"container mx-auto text-center"}>
                        <h1 className={"font-agrandir text-white"}>Stay connected</h1>
                        <div className={"flex flex-wrap mx-auto py-4"}>
                            <div className={"flex flex-wrap items-stretch lg:w-1/5 mx-auto"}>
                                <div className={"flex-1 px-1"}>
                                    <a href="https://www.facebook.com/thedropfestival/" target="_blank" rel="noopener noreferrer">
                                        <img className={"mx-auto"} src={FacebookIcon} alt="" />
                                    </a>
                                </div>
                                <div className={"flex-1 px-1"}>
                                    <a href="https://www.instagram.com/thedropfestival" target="_blank" rel="noopener noreferrer">
                                        <img className={"mx-auto"} src={InstagramIcon} alt="" />
                                    </a>
                                </div>
                                <div className={"flex-1 px-1"}>
                                    <a href="https://www.youtube.com/channel/UC2R6luglFhbFyPDVjr2UNhA" target="_blank" rel="noopener noreferrer">
                                        <img className={"mx-auto"} src={YoutubeIcon} alt="" />
                                    </a>
                                </div>
                                <div className={"flex-1 px-1"}>
                                    <a href="https://open.spotify.com/user/nmv5ubpzdxs8si8w1nxnjtcm5" target="_blank" rel="noopener noreferrer">
                                        <img className={"mx-auto"} src={SpotifyIcon} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={"bg-black w-full py-8"}>
                    <div className={"container mx-auto"}>
                        <div className={"flex flex-wrap py-8 px-4 md:px-4 lg:px-0"}>
                            <div className={"text-center mb-12 md:mb-0 md:text-left w-full md:w-1/3 lg:w-1/4 text-white"}>
                                <img className={"hidden md:block"} src={Logo} alt="logo" />
                                <img className={"block md:hidden mx-auto w-1/2"} src={LogoDarkLG} alt="logo" />
                                <div className={"mt-4 md:mt-12"}>
                                    <span className={"font-montserrat-regular text-sm text-gray-600 hover:text-white"}>© The Drop 2019</span>
                                    <br/>
                                    {/* <span className={"font-montserrat-regular text-sm text-gray-700 hover:text-white"}>site by <a href="https://slik.com.au" rel="noopener noreferrer" target="_blank">SLIK</a></span> */}
                                </div>
                            </div>
                            <div className={"invisible lg:visible lg:w-1/4 text-white"}></div>
                            <div className={"mb-8 md:mb-0 w-full md:w-1/3 lg:w-1/4 text-white"}>
                                <ul className={"list-none"}>
                                    <li className={"mb-6"}>
                                        <p className={"font-agrandir"}>The nitty gritty</p>
                                    </li>
                                    <li className={"mb-6"}>
                                        <Link to={`/${locale}/articles/the-drop-2020-privacy-policy/`}>
                                            <span className={"font-montserrat-regular text-sm text-gray-600 hover:text-white"}>Privacy Policy</span>
                                        </Link>
                                    </li>
                                    <li className={"mb-6"}>
                                        <Link to={`/${locale}/articles/terms-and-conditions/`}>
                                            <span className={"font-montserrat-regular text-sm text-gray-600 hover:text-white"}>Terms &amp; conditions</span>
                                        </Link>
                                    </li>
                                    <li className={"mb-6"}>
                                        <Link to={`/${locale}/articles/copyright-policy/`}>
                                            <span className={"font-montserrat-regular text-sm text-gray-600 hover:text-white"}>Copyright</span>
                                        </Link>
                                    </li>
                                    <li className={"mb-6"}>
                                        <Link to={`/${locale}/articles/cookie-policy/`}>
                                            <span className={"font-montserrat-regular text-sm text-gray-600 hover:text-white"}>Cookie policy</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className={"mb-4 md:mb-0 w-full md:w-1/3 lg:w-1/4 text-white"}>
                                <ul className={"list-none"}>
                                    <li className={"mb-5"}>
                                        <p className={"font-agrandir"}>Get in touch</p>
                                    </li>
                                    <li className={"mb-5"}>
                                        <span className={"font-montserrat-regular text-sm text-white"}><strong>General Enquiries</strong></span><br />
                                        <a href="mailto:info@thedroplive.com" className={"font-montserrat-regular text-sm md:text-tiny text-gray-600 hover:text-white"} >info@thedroplive.com</a>
                                    </li>
                                    <li className={"mb-5"}>
                                        <span className={"font-montserrat-regular text-sm text-white"}><strong>Marketing Enquiries</strong></span><br />
                                        <a href="mailto:marketing@thedroplive.com" className={"font-montserrat-regular text-sm md:text-tiny text-gray-600 hover:text-white"}>marketing@thedroplive.com</a>
                                    </li>
                                    {/* <li className={"mb-5"}>
                                        <span className={"font-montserrat-regular text-sm text-white"}><strong>Publicity Enquiries</strong></span><br />
                                        <a href="mailto:admin@scrabblepr.com.au" className={"font-montserrat-regular text-sm md:text-tiny text-gray-600 hover:text-white"}>admin@scrabblepr.com.au</a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        )
    }

}

Footer.defaultProps = {
    theme:'light'
}

Footer.propTypes = {
    theme:PropTypes.string
}

export default Footer
