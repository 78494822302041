import React from "react"

import Button from "../button/button"
import PageTransitionLink from "../../components/transition/link"
import LogoLightLG from "../../images/logo-light.svg"
import LogoDarkLG from "../../images/logo-dark.svg"
import LogoLightMD from "../../images/logo-light-md.svg"
import LogoDarkMD from "../../images/logo-dark-md.svg"
import "./navigation.scss"

class Navigation extends React.Component {

    render(){
        return (
            <header className={`${(this.props.theme === 'dark' ? 'bg-black border-gray-900' : 'bg-white border-gray-300')} w-full border-solid border relative`}>
                <div className={"container mx-auto lg:px-8"}>
                    <nav className={"main-navigation flex flex-row items-stretch list-none py-4 leading-loose"}>
                        <li className={"flex-initial py-1 md:pl-4 lg:pl-0 lg:mr-6 cursor-pointer"}>
                            <PageTransitionLink href={'/en-AU'} exit={this.props.exit} entry={this.props.entry}>
                                <img className={"md:hidden lg:inline-block lg:pr-5 lg:mr-8 w-full"} src={(this.props.theme === 'dark' ? LogoLightLG : LogoDarkLG)} style={{maxWidth:'200px'}} alt=""/>
                                <img className={"md:inline-block lg:hidden w-8 md:mr-6"} src={(this.props.theme === 'dark' ? LogoLightMD : LogoDarkMD)} style={{maxWidth:'200px'}} alt=""/>
                            </PageTransitionLink>
                        </li>
                        <li className={"flex-grow md:px-0 lg:px-1 py-1 lg:mt-1 text-center relative cursor-pointer"}>
                            <PageTransitionLink href={'/en-AU/festivals'} exit={this.props.exit} entry={this.props.entry}>
                                <span className={`${(this.props.theme === 'dark' ? 'text-white' : 'text-black')} uppercase font-agrandir md:text-xs lg:text-base`}>festivals</span>
                            </PageTransitionLink>
                        </li>
                        <li className={"flex-grow md:px-0 lg:px-1 py-1 lg:mt-1 text-center relative cursor-pointer"}>
                            <PageTransitionLink href={'/en-AU/music'} exit={this.props.exit} entry={this.props.entry}>
                                <span className={`${(this.props.theme === 'dark' ? 'text-white' : 'text-black')} uppercase font-agrandir md:text-xs lg:text-base`}>music</span>
                            </PageTransitionLink>
                        </li>
                        {/* <li className={"flex-grow md:px-0 lg:px-1 py-1 lg:mt-1 text-center relative cursor-pointer"}>
                            <PageTransitionLink href={'/en-AU/tv'} exit={this.props.exit} entry={this.props.entry}>
                                <span className={`${(this.props.theme === 'dark' ? 'text-white' : 'text-black')} uppercase font-agrandir md:text-xs lg:text-base`}>drop tv</span>
                            </PageTransitionLink>
                        </li> */}
                        <li className={"flex-grow md:px-0 lg:px-1 py-1 lg:mt-1 text-center relative cursor-pointer"}>
                            <PageTransitionLink href={'/en-AU/surfing'} exit={this.props.exit} entry={this.props.entry}>
                                <span className={`${(this.props.theme === 'dark' ? 'text-white' : 'text-black')} uppercase font-agrandir md:text-xs lg:text-base`}>surfing</span>
                            </PageTransitionLink>
                        </li>
                        {/* <li className={"flex-grow md:px-0 lg:px-1 py-1 lg:mt-1 text-center relative cursor-pointer"}>
                            <PageTransitionLink href={'/en-AU/lifestyle'} exit={this.props.exit} entry={this.props.entry}>
                                <span className={`${(this.props.theme === 'dark' ? 'text-white' : 'text-black')} uppercase font-agrandir md:text-xs lg:text-base`}>lifestyle</span>
                            </PageTransitionLink>
                        </li> */}
                        {/* <li className={"flex-shrink md:px-0 lg:px-1 py-1 pt-3 lg:mt-1 text-center align-middle cursor-pointer"}>
                            <PageTransitionLink href={'/en-AU/search'} exit={this.props.exit} entry={this.props.entry}>
                                <img src={(this.props.theme === 'dark' ? SearchIconLight : SearchIconDark)} className={"md:px-4 lg:px-8 mx-auto"} alt=""/>
                            </PageTransitionLink>
                        </li> */}
                        <li className={"flex-shrink md:px-0 md:pr-4 lg:ml-8 lg:pr-0 py-1 text-right"}>
                          <a href="https://www.eventbrite.com.au/o/the-drop-festival-18287173371" target="_blank" rel="noopener noreferrer">
                            <Button label={"buy tickets"} type={"outline"} />
                          </a>
                        </li>
                    </nav>
                </div>
            </header>
        )
    }

}

export default Navigation
