module.exports = {
    langs: ['en-AU', 'fr-FR', 'ja-JP', 'pt-BR'],
    defaultLangKey: 'en-AU',
    messages: [
        {
            code: 'en-AU',
            label: 'English'
        }, 
        {
            code: 'fr-FR',
            label: 'French'
        }, 
        {
            code: 'ja-JP',
            label: 'Japanese'
        }, 
        {
            code: 'pt-BR',
            label: 'Portuguese'
        }   
    ]
}