import React from "react"
import { TweenMax, Sine } from "gsap"
import Cookie from "universal-cookie"

import FacebookIcon from "../../images/facebook-icon.svg"
import InstagramIcon from "../../images/instagram-icon.svg"
import YoutubeIcon from "../../images/youtube-icon.svg"
import SpotifyIcon from "../../images/spotify-icon.svg"
import Languages from "../../lib/languages"
import "./language.scss"

class LanguageSelector extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      languageOpen: false,
      connectedOpen: false,
    }

    this.languageDraw = null
    this.connectedDraw = null
    this.height = 76
  }

  toggleLanguage = () => {

    if(this.state.connectedOpen) this.toggleConnected()

    if (this.state.languageOpen) {
      TweenMax.to(this.languageDraw, 0.1, { height: 0, ease: Sine.easeOut })
      this.setState({ languageOpen: false })
    } else {
      TweenMax.to(this.languageDraw, 0.1, {
        height: `${this.height}px`,
        ease: Sine.easeOut,
      })
      this.setState({ languageOpen: true })
    }
  }

  toggleConnected = () => {

    if(this.state.languageOpen) this.toggleLanguage()

    if (this.state.connectedOpen) {
      TweenMax.to(this.connectedDraw, 0.1, { height: 0, ease: Sine.easeOut })
      this.setState({ connectedOpen: false })
    } else {
      TweenMax.to(this.connectedDraw, 0.1, {
        height: `${this.height}px`,
        ease: Sine.easeOut,
      })
      this.setState({ connectedOpen: true })
    }
  }

  setLanguage(item) {
    
    const cookie = new Cookie()
    cookie.set('drop_lang', item.code)
    this.setState({language:item.code})

    // const fragment = this.props.path.split('/')[1]
    // let url = this.props.path.replace(fragment, item.code)
    // return navigate(url)

  }

  render() {
    return (
      <div className={"clearfix"}>
        <header
          className={"bg-black w-full border border-solid border-gray-900"}
        >
          <div className={"container mx-auto lg:px-6"}>
            <div className={"flex justify-between p-2"}>
              <span
                className={"font-montserrat-extra-bold text-xs text-white"}
                
              >
                {/* en-AU */}
              </span>
              <span
                className={"font-montserrat-extra-bold text-xs text-white cursor-pointer hover:text-coral"}
                onClick={this.toggleConnected}
              >
                stay connected
              </span>
            </div>
          </div>
        </header>
        <section
          className={`gravel-pattern h-0 clearfix  ${(this.state.connectedOpen ? "hidden" : "visible")}`}
          ref={div => (this.languageDraw = div)}
        >
          <nav className={"container mx-auto py-6"}>
            {Languages.messages.map((item,index) => {
                return (
                  <li className={"inline-block cursor-pointer"} onClick={() => this.setLanguage(item)} key={`lang-${index}`}>
                    <span className={"text-white pr-6"}><strong className={"text-white"}>{item.code}</strong> {item.label}</span>
                  </li>
                )
              })
            }
          </nav>
        </section>
        <section
          className={`coral-pattern h-0 clearfix ${(this.state.languageOpen ? "hidden" : "visible")}`}
          ref={div => (this.connectedDraw = div)}
        >
          <nav className={"container relative list-none mx-auto py-6"}>
            <li className={"social-icon facebook"}>
              <a href="https://www.facebook.com/thedropfestival/" target="_blank" rel="noopener noreferrer">
                <img src={FacebookIcon} alt="" />
              </a>
            </li>
            <li className={"social-icon instagram"}>
              <a href="https://www.instagram.com/thedropfestival" target="_blank" rel="noopener noreferrer">
                <img src={InstagramIcon} alt="" />
              </a>
            </li>
            <li className={"social-icon youtube"}>
              <a href="https://www.youtube.com/channel/UC2R6luglFhbFyPDVjr2UNhA" target="_blank" rel="noopener noreferrer">
                <img src={YoutubeIcon} alt="" />
              </a>
            </li>
            <li className={"social-icon spotify"}>
              <a href="https://open.spotify.com/user/nmv5ubpzdxs8si8w1nxnjtcm5" target="_blank" rel="noopener noreferrer">
                <img src={SpotifyIcon} alt="" />
              </a>
            </li>
          </nav>
        </section>
      </div>
    )
  }

}

export default LanguageSelector
